<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" size="16">mdi-help-circle-outline</v-icon>
    </template>
      <slot name="default"></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'PopupHint',
  data: () => ({
  })
}
</script>
<style scoped lang="scss">
  .v-icon{
    // display: inline-block;
    margin: 0 .2em;
  }
</style>

<template>
  <div id="request" class="pb-6">

    <v-app-bar flat inverted-scroll scroll-threshold="200" scroll-off-screen>
      <v-app-bar-title>
        {{ direction.direction_name }}
      </v-app-bar-title>
      <template v-slot:extension>
        <!-- tabs button -->
        <v-tabs v-model="selectedTabs" @change="onFixedTabChange" center-active>
          <v-tab class="ml-5"><v-icon left>mdi-file-sign</v-icon>案件情報</v-tab>
          <v-tab><v-icon left>mdi-file-document-check-outline</v-icon>ご提出物の進捗状況</v-tab>
          <v-tab><v-icon left>mdi-checkbox-marked-circle-outline</v-icon>制作物のご確認</v-tab>
          <v-tab><v-icon left>mdi-folder</v-icon>ファイル</v-tab>
          <v-tab><v-icon left>mdi-cog-outline</v-icon>設定</v-tab>
        </v-tabs>
        <!-- tab contents -->
      </template>
    </v-app-bar>

    <v-toolbar height="auto" flat extension-height="auto">
      <v-toolbar-title>
        <span class="font-weight-bold">{{ direction.direction_name }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="selectedTabs" show-arrows="" center-active class="mb-6">
      <!-- tabs button -->
      <v-tab class="ml-5"><v-icon left>mdi-file-sign</v-icon>案件情報</v-tab>
      <v-tab><v-icon left>mdi-file-document-check-outline</v-icon>ご提出物の進捗状況</v-tab>
      <v-tab><v-icon left>mdi-checkbox-marked-circle-outline</v-icon>制作物のご確認</v-tab>
      <v-tab><v-icon left>mdi-folder</v-icon>ファイル</v-tab>
      <v-tab><v-icon left>mdi-cog-outline</v-icon>設定</v-tab>
      <!-- tab contents -->
      <v-tab-item>
        <v-container>
          <v-alert color="info" border="left" colored-border class="pt-3 pr-3 pb-0 pl-6">
            <v-row dense class="mb-3">
              <v-col>
                <h3 class="text-h7">{{ direction.direction_customer_person_name }}<v-icon v-if="loginUserInfo.is_admin_user && direction.end_user" small right style="vertical-align: baseline;">mdi-link-variant</v-icon></h3>
              </v-col>
              <v-col align="end">
                <v-btn small text v-if="loginUserInfo.is_admin_user" @click="editCustomerInfo()"><v-icon small>mdi-pencil</v-icon>編集</v-btn>
              </v-col>
            </v-row>
            <v-row dense class="mb-3">
              <v-col cols="12" md="2">
                <h4>ご担当者名</h4>
              </v-col>
              <v-col cols="12" md="10">
                <span v-text="direction.direction_customer_person_name2" class="whitespace-pre-wrap"></span>
              </v-col>
              <v-col cols="12" md="2">
                <h4>連絡先</h4>
              </v-col>
              <v-col cols="12" md="10">
                <v-row dense>
                  <v-col cols="12">
                    <span v-text="direction.direction_customer_mailaddress" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12">
                    <span v-text="direction.direction_customer_tel" class="whitespace-pre-wrap"></span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="2">
                <h4>代理店名</h4>
              </v-col>
              <v-col cols="12" md="10">
                {{ direction.agent_id }}
              </v-col>
            </v-row>
            <v-divider />
            <v-list v-if="direction.business_person" class="d-inline-block py-0">
              <v-list-item class="pl-0 pr-6">
                <v-list-item-avatar class="mr-2" style="height: auto; min-width: auto; width: auto;">
                  <LoginAvatar :user="direction.business_person" :size="40" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ '営業担当：' + direction.business_person.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list v-if="direction.direction_director_person" class="d-inline-block py-0">
              <v-list-item class="pl-0 pr-6">
                <v-list-item-avatar class="mr-2" style="height: auto; min-width: auto; width: auto;">
                  <LoginAvatar :user="direction.direction_director_person" :size="40" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ '担当ディレクター：' + direction.direction_director_person.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-dialog max-width="640px" persistent v-model="crud.customerInfo.edit">
              <v-form ref="customerInfo" v-model="crud.customerInfo.valid">
                <v-card>
                  <v-card-title>お客様情報の編集</v-card-title>
                  <v-divider />
                  <v-card-text class="pt-3">
                    <v-container>
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <v-text-field label="会社名" type="text" v-model="crud.customerInfo.restore.direction_customer_person_name" :rules="[inputRules.required]" class="required" :disabled="direction.end_user_id ? true : false"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea label="ご担当者名" v-model="crud.customerInfo.restore.direction_customer_person_name2" rows="1" auto-grow></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea label="メールアドレス" hint="こちらに入力されたメールアドレスは表示専用で、通知や連絡は行われません。" v-model="crud.customerInfo.restore.direction_customer_mailaddress" rows="1" auto-grow></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-textarea label="お電話番号" v-model="crud.customerInfo.restore.direction_customer_tel" rows="1" auto-grow></v-textarea>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-text-field label="代理店名" type="text" v-model="crud.customerInfo.restore.agent_id"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select class="required" v-model="crud.customerInfo.restore.business_person_id" :rules="[inputRules.requiredSelect]" item-text="name" item-value="id" :items="direction?.admin_company?.logins" label="営業担当" persistent-hint single-line clearable></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select v-model="crud.customerInfo.restore.direction_director_person_id" item-text="name" item-value="id" :items="direction?.admin_company?.logins" label="担当ディレクター" persistent-hint single-line clearable></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="cancelEditCustomerInfo()">キャンセル</v-btn>
                    <v-btn class="primary" @click="updateCustomerInfo()" :disabled="!crud.customerInfo.valid">保存</v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-alert>
          <v-expansion-panels v-model="infoPanels" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  基本情報<v-btn icon small class="ml-2" @click.stop="editBasicInfo()" v-if="infoPanels.includes(0) && loginUserInfo.is_admin_user"><v-icon small>mdi-pencil</v-icon></v-btn>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="12" md="2">
                    <h4>プラン名</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_type" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>案件種別</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_type2" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>サイトページ数</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_site_num" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>納品予定日</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span>{{ direction.direction_delivery_date | moment("YYYY/M/D(ddd)") }}</span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>スマートフォン対応</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_sp" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>最大納期</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span>{{ direction.direction_deadline | moment("YYYY/M/D(ddd)") }}</span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>取材撮影</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_interview" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>CMS</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_cms" class="whitespace-pre-wrap"></span>
                  </v-col>
                </v-row>
                <v-dialog max-width="640px" persistent v-model="crud.basicInfo.edit">
                  <v-form ref="basicInfo" v-model="crud.basicInfo.valid">
                    <v-card>
                      <v-card-title>基本情報の編集</v-card-title>
                      <v-divider />
                      <v-card-text class="pt-3">
                        <v-row dense>
                          <v-col cols="12" md="6">
                            <v-text-field label="プラン名" type="text" v-model="crud.basicInfo.restore.direction_type"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-combobox label="案件種別" v-model="crud.basicInfo.restore.direction_type2" :items="[
                              '新規コーポレートサイト',
                              '新規リクルートサイト',
                              'コーポレートサイトリニューアル',
                              'リクルートサイトリニューアル',
                              'パンフレット',
                              'LP',
                              '動画',
                              'その他'
                            ]" clearable></v-combobox>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field label="サイトページ数" type="text" v-model="crud.basicInfo.restore.direction_site_num"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field label="納品予定日" type="date" v-model="crud.basicInfo.restore.direction_delivery_date"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-combobox label="スマートフォン対応" v-model="crud.basicInfo.restore.direction_sp" :items="[
                              'PCのみ',
                              'PC＋スマートフォン',
                              'PC＋タブレット＋スマートフォン',
                              'スマートフォンのみ',
                              'レスポンシブ'
                            ]" clearable></v-combobox>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field label="最大納期" type="date" v-model="crud.basicInfo.restore.direction_deadline"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-combobox label="取材撮影" v-model="crud.basicInfo.restore.direction_interview" :items="[
                              '訪問取材',
                              'リモート取材',
                              '撮影',
                              '訪問取材＋撮影',
                              'リモート取材＋撮影',
                              'なし'
                            ]" clearable></v-combobox>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-combobox label="CMS" v-model="crud.basicInfo.restore.direction_cms" :items="[
                              'Wordpress',
                              'MakeShop',
                              'その他',
                              '不要'
                            ]" clearable></v-combobox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditBasicInfo()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateBasicInfo()" :disabled="!crud.basicInfo.valid">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  システム<v-btn icon small class="ml-2" @click.stop="editSystemInfo()" v-if="infoPanels.includes(1) && loginUserInfo.is_admin_user"><v-icon small>mdi-pencil</v-icon></v-btn>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="12" md="2">
                    <h4>ドメイン取得希望</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_domain_get" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>ドメイン</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_domain" class="whitespace-pre-wrap" v-linkified></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>SSL</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_ssl" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>サーバ取得管理</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_server_manage" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>フォーム設定用アドレス</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_form_mailaddress" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>サーバ情報</h4>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span v-text="direction.direction_server_information" class="whitespace-pre-wrap" v-linkified></span>
                  </v-col>
                </v-row>
                <v-dialog max-width="640px" persistent v-model="crud.systemInfo.edit">
                  <v-form ref="systemInfo" v-model="crud.systemInfo.valid">
                    <v-card>
                      <v-card-title>システムの編集</v-card-title>
                      <v-divider />
                      <v-card-text class="pt-3">
                        <v-row dense>
                          <v-col cols="12" md="6">
                            <v-combobox label="ドメイン取得希望" v-model="crud.systemInfo.restore.direction_domain_get" :items="[
                              '取得済み',
                              '取得希望'
                            ]" clearable></v-combobox>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field label="ドメイン" type="text" v-model="crud.systemInfo.restore.direction_domain"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-combobox label="SSL" v-model="crud.systemInfo.restore.direction_ssl" :items="[
                              '要',
                              '不要'
                            ]" clearable></v-combobox>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-combobox label="サーバ取得管理" v-model="crud.systemInfo.restore.direction_server_manage" :items="[
                              'お客様',
                              '当社'
                            ]" clearable></v-combobox>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field label="フォーム設定用アドレス" type="text" v-model="crud.systemInfo.restore.direction_form_mailaddress"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-textarea label="サーバ情報" v-model="crud.systemInfo.restore.direction_server_information" rows="1" auto-grow></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditSystemInfo()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateSystemInfo()" :disabled="!crud.systemInfo.valid">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  コンセプト・デザイン<v-btn icon small class="ml-2" @click.stop="editConceptInfo()" v-if="infoPanels.includes(2) && loginUserInfo.is_admin_user"><v-icon small>mdi-pencil</v-icon></v-btn>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="12" md="2">
                    <h4>サイトの目的・成果</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <span v-text="direction.direction_purpose" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>商材およびサービス内容</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <span v-text="direction.direction_content" class="whitespace-pre-wrap"></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>ターゲット</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <v-row dense>
                      <v-col cols="12" v-if="direction.direction_target_sex"><span class="whitespace-pre-wrap">性別：{{ direction.direction_target_sex ? direction.direction_target_sex.join('、') : '' }}</span></v-col>
                      <v-col cols="12" v-if="direction.direction_target_age"><span class="whitespace-pre-wrap">年齢：{{ direction.direction_target_age ? direction.direction_target_age.join('、') : '' }}</span></v-col>
                      <v-col cols="12" v-if="direction.direction_target_type"><span class="whitespace-pre-wrap">属性：{{ direction.direction_target_type ? direction.direction_target_type.join('、') : '' }}</span></v-col>
                      <v-col cols="12" v-if="direction.direction_target"><span class="whitespace-pre-wrap">その他：{{ direction.direction_target }}</span></v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>キーワード</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <v-row dense>
                      <v-col cols="12" v-if="direction.direction_keyword"><span class="whitespace-pre-wrap">メイン：{{ direction.direction_keyword }}</span></v-col>
                      <v-col cols="12" v-if="direction.direction_sub_keyword"><span class="whitespace-pre-wrap">サブ：{{ direction.direction_sub_keyword }}</span></v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="my-4" />
                <v-row dense>
                  <v-col cols="12" md="2">
                    <h4>参考サイト</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <span v-text="direction.direction_reference" class="whitespace-pre-wrap" v-linkified></span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>サイトカラー</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <span class="whitespace-pre-wrap">{{ direction.direction_site_color ? direction.direction_site_color.join('、') : '' }}</span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>トーン＆マナー</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <span class="whitespace-pre-wrap">{{ direction.direction_site_tone ? direction.direction_site_tone.join('、') : '' }}</span>
                  </v-col>
                  <v-col cols="12" md="2">
                    <h4>トーン＆マナー特記事項</h4>
                  </v-col>
                  <v-col cols="12" md="10">
                    <span v-text="direction.direction_site_tone_note" class="whitespace-pre-wrap" v-linkified></span>
                  </v-col>
                </v-row>
                <v-dialog max-width="800px" persistent v-model="crud.conceptInfo.edit">
                  <v-form ref="conceptInfo" v-model="crud.conceptInfo.valid">
                    <v-card>
                      <v-card-title>コンセプト・デザインの編集</v-card-title>
                      <v-divider />
                      <v-card-text class="pt-3">
                        <v-card flat>
                          <v-card-title class="pt-4 pb-4">コンセプト</v-card-title>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-textarea label="サイトの目的・成果" v-model="crud.conceptInfo.restore.direction_purpose" rows="1" auto-grow></v-textarea>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-textarea label="商材およびサービス内容" v-model="crud.conceptInfo.restore.direction_content" rows="1" auto-grow></v-textarea>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-combobox label="ターゲット（性別）" v-model="crud.conceptInfo.restore.direction_target_sex" :items="[
                                  '男性',
                                  '女性'
                                ]" multiple small-chips deletable-chips clearable></v-combobox>
                              </v-col>
                              <v-col cols="12" md="7">
                                <v-combobox label="ターゲット（年齢）" v-model="crud.conceptInfo.restore.direction_target_age" :items="[
                                  '10代',
                                  '20代',
                                  '30代',
                                  '40代',
                                  '50代',
                                  '60代～'
                                ]" multiple small-chips deletable-chips clearable></v-combobox>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-combobox label="ターゲット（属性）" v-model="crud.conceptInfo.restore.direction_target_type" :items="[
                                  '子供',
                                  '学生',
                                  '会社員',
                                  '自営業',
                                  '未婚',
                                  '既婚',
                                  '妊婦',
                                  '子育て',
                                  '外国人',
                                  'その他'
                                ]" multiple small-chips deletable-chips clearable></v-combobox>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-textarea label="ターゲット（その他）" v-model="crud.conceptInfo.restore.direction_target" rows="1" auto-grow></v-textarea>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field label="キーワード（メイン）" type="text" v-model="crud.conceptInfo.restore.direction_keyword"></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field label="キーワード（サブ）" type="text" v-model="crud.conceptInfo.restore.direction_sub_keyword"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card flat>
                          <v-card-title class="pt-0 pb-4">デザイン</v-card-title>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12" md="12">
                                <v-textarea label="参考サイト" v-model="crud.conceptInfo.restore.direction_reference" rows="1" auto-grow></v-textarea>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-combobox label="サイトカラー" v-model="crud.conceptInfo.restore.direction_site_color" :items="[
                                  '赤系（快活・若々しい・温かみ）',
                                  'ピンク系（かわいい・エレガント）',
                                  '紫系（幻想・神秘的）',
                                  '青系（堅実・信頼・クール）',
                                  '水色系（さわやか・清潔感）',
                                  '緑系（健康・ナチュラル）',
                                  '黄色・オレンジ系（ポップ・にぎやか）',
                                  '茶色系（自然・モダン・高級感）',
                                  '白系（シンプル・綺麗）',
                                  'グレー系（重厚・シック・高級感）',
                                  '黒系（かっこいい・高級感）'
                                ]" multiple small-chips deletable-chips clearable></v-combobox>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-combobox label="トーン＆マナー" v-model="crud.conceptInfo.restore.direction_site_tone" :items="[
                                  '可愛い・キュート',
                                  'ポップ',
                                  'カラフル',
                                  'にぎやか',
                                  '派手',
                                  'カジュアル',
                                  'ナチュラル',
                                  '親しみやすさ',
                                  '自然・環境',
                                  '優しい',
                                  '誠実',
                                  '清潔感',
                                  '爽やか',
                                  '透明感',
                                  '和風',
                                  'シンプル',
                                  'シック',
                                  'クール',
                                  '高級感',
                                  '重厚な',
                                  'メタリック',
                                  '安心感・信頼感',
                                  'イラスト',
                                  'エレガント',
                                  '幻想・神秘的',
                                  '挑戦・情熱'
                                ]" multiple small-chips deletable-chips clearable></v-combobox>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-textarea label="トーン＆マナー特記事項" v-model="crud.conceptInfo.restore.direction_site_tone_note" rows="1" auto-grow></v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditConceptInfo()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateConceptInfo()" :disabled="!crud.conceptInfo.valid">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  特記事項<v-btn icon small class="ml-2" @click.stop="editNoticeInfo()" v-if="infoPanels.includes(3) && loginUserInfo.is_admin_user"><v-icon small>mdi-pencil</v-icon></v-btn>
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col cols="12" md="12">
                    <span v-text="direction.direction_notices" class="whitespace-pre-wrap" v-linkified></span>
                  </v-col>
                </v-row>
                <v-dialog max-width="700px" persistent v-model="crud.noticeInfo.edit">
                  <v-form ref="noticeInfo" v-model="crud.noticeInfo.valid">
                    <v-card>
                      <v-card-title>特記事項の編集</v-card-title>
                      <v-divider />
                      <v-card-text class="pt-3">
                        <v-row dense>
                          <v-col cols="12" md="12">
                            <v-textarea label="特記事項" v-model="crud.noticeInfo.restore.direction_notices" outlined auto-grow hide-details></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditNoticeInfo()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateNoticeInfo()" :disabled="!crud.noticeInfo.valid">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-tab-item>
      <!-- tab contents -->
      <v-tab-item>
        <v-divider />
        <v-container>
          <v-card>
            <v-card-title>
              データ
              <v-spacer />
              <v-btn text v-if="loginUserInfo.is_admin_user" @click="$refs.direction_dataoffer.openAddDialog()"><v-icon small>mdi-plus-circle</v-icon>追加</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <DirectionDataoffer ref="direction_dataoffer" :id="direction.id" @change="readDataFromAPI" />
            </v-card-text>
          </v-card>
        </v-container>
      </v-tab-item>
      <!-- tab contents -->
      <v-tab-item>
        <v-divider />
        <v-container>
          <v-card>
            <v-card-title>
              デザイン
              <v-spacer />
              <v-btn text v-if="loginUserInfo.is_admin_user" @click="$refs.direction_site.openAddDialog()"><v-icon small>mdi-plus-circle</v-icon>追加</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <DirectionSite ref="direction_site" :id="direction.id" @change="readDataFromAPI" />
            </v-card-text>
          </v-card>
        </v-container>
        <v-container>
          <v-card>
            <v-card-title>
              仮アップ情報
              <v-spacer />
              <v-btn text v-if="loginUserInfo.is_admin_user" @click="editReviewInfo()"><v-icon small>mdi-pencil</v-icon>編集</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <template>
                <span v-text="direction.direction_url" class="whitespace-pre-wrap" v-linkified></span>
              </template>
              <template v-if="direction.direction_url">
                <v-divider class="my-4" />
                <v-row dense>
                  <v-col cols="12" md="12">
                    <div class="d-inline-block align-top pr-6">
                      <template v-if="direction.direction_approval"><v-icon color="success" class="mr-1">mdi-checkbox-marked-circle-outline</v-icon>承認</template>
                      <template v-else>
                        <confirm-dialog @accept="approvalReviewInfo()" type="success" btnTitle="承認する">
                          <template v-slot:title>仮アップサイトを承認しますか？</template>
                          <template v-slot:message>仮アップサイトをご確認いただき、問題なければ<b>「承認する」</b>を、修正などございましたら<b>「キャンセル」</b>をクリックして、コメントよりお知らせください。</template>
                          <template v-slot:activator="{ on }">
                            <v-btn @click.stop="on" block text class="px-2" style="font-size: 1rem;"><v-icon color="gray" class="mr-1">mdi-checkbox-marked-circle-outline</v-icon>仮アップサイトのご承認</v-btn>
                          </template>
                        </confirm-dialog>
                      </template>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
          <v-dialog max-width="640px" persistent v-model="crud.reviewInfo.edit">
            <v-form ref="reviewInfo" v-model="crud.reviewInfo.valid">
              <v-card>
                <v-card-title>仮アップ情報の編集</v-card-title>
                <v-divider />
                <v-card-text class="pt-3">
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-textarea label="アクセス情報（URL、ID・パスワード等）" v-model="crud.reviewInfo.restore.direction_url" outlined auto-grow hide-details></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-checkbox label="承認チェック" v-model="crud.reviewInfo.restore.direction_approval" :false-value="0" :true-value="1" color="success" dense hide-details class="pa-0 ma-0"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="cancelEditReviewInfo()">キャンセル</v-btn>
                  <v-btn class="primary" @click="updateReviewInfo()" :disabled="!crud.reviewInfo.valid">保存</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-container>
        <v-container>
          <v-card>
            <v-card-title>
              本アップ情報
              <v-spacer />
              <v-btn text v-if="loginUserInfo.is_admin_user" @click="editDeliveryInfo()"><v-icon small>mdi-pencil</v-icon>編集</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <template>
                <span v-text="direction.direction_production_url" class="whitespace-pre-wrap" v-linkified></span>
              </template>
            </v-card-text>
          </v-card>
          <v-dialog max-width="640px" persistent v-model="crud.deliveryInfo.edit">
            <v-form ref="deliveryInfo" v-model="crud.deliveryInfo.valid">
              <v-card>
                <v-card-title>本アップ情報の編集</v-card-title>
                <v-divider />
                <v-card-text class="pt-3">
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-textarea label="アクセス情報（URL、ID・パスワード等）" v-model="crud.deliveryInfo.restore.direction_production_url" outlined auto-grow hide-details></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="cancelEditDeliveryInfo()">キャンセル</v-btn>
                  <v-btn class="primary" @click="updateDeliveryInfo()" :disabled="!crud.deliveryInfo.valid">保存</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-container>
      </v-tab-item>
      <!-- tab contents -->
      <v-tab-item>
        <v-divider />
        <v-container>
          <v-card>
            <v-card-title>
              ファイル
              <v-spacer />
              <v-btn text v-if="loginUserInfo.is_admin_user" @click="$refs.direction_file.openAddDialog()"><v-icon small>mdi-plus-circle</v-icon>追加</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <DirectionFile ref="direction_file" :id="direction.id" @change="readDataFromAPI" />
            </v-card-text>
          </v-card>
        </v-container>
      </v-tab-item>
      <!-- tab contents -->
      <v-tab-item>
        <v-divider />
        <v-container>
          <v-card>
            <v-card-title>
              メッセージ通知先
              <v-spacer />
              <v-btn text v-if="loginUserInfo.is_admin_user" @click="editContactInfo()"><v-icon small>mdi-pencil</v-icon>編集</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <template>
                <h4>通知先アドレス（to）</h4>
                <pre><span v-for="(value, i) in direction.direction_board_mail_to" :key="i" class="d-block"><v-icon small class="mr-1">mdi-email</v-icon>{{ value }}</span></pre>
              </template>
              <template>
                <h4>通知先アドレス（cc）</h4>
                <pre><span v-for="(value, i) in direction.direction_board_mail_cc" :key="i" class="d-block"><v-icon small class="mr-1">mdi-email</v-icon>{{ value }}</span></pre>
              </template>
            </v-card-text>
          </v-card>
          <v-dialog max-width="640px" persistent v-model="crud.contactInfo.edit">
            <v-form ref="contactInfo" v-model="crud.contactInfo.valid">
              <v-card>
                <v-card-title>メッセージ通知先の編集</v-card-title>
                <v-divider />
                <v-card-text class="pt-3">
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-combobox label="通知先アドレス（to）" v-model="crud.contactInfo.restore.direction_board_mail_to" multiple chips deletable-chips clearable></v-combobox>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-combobox label="通知先アドレス（cc）" v-model="crud.contactInfo.restore.direction_board_mail_cc" multiple chips deletable-chips clearable></v-combobox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="cancelEditContactInfo()">キャンセル</v-btn>
                  <v-btn class="primary" @click="updateContactInfo()" :disabled="!crud.contactInfo.valid">保存</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-container>
        <v-container v-if="loginUserInfo.is_admin_user">
          <v-card>
            <v-card-title>
              共有設定
              <v-spacer />
              <v-btn text v-if="loginUserInfo.is_admin_user" @click="editShareInfo()"><v-icon small>mdi-pencil</v-icon>編集</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <template>
                <h4>アクセスできるクライアント</h4>
                <pre v-if="direction.end_user"><router-link :to="{ name: 'admin:client_show', params: { id: direction.end_user_id } }"><v-icon small class="mr-1">mdi-domain</v-icon>{{ direction.end_user.end_company_name }}</router-link></pre>
                <pre v-else>設定されていません。</pre>
              </template>
              <template>
                <h4>ゲストアクセス</h4>
                <pre v-if="direction.guest_shared">アクセスが許可されています。<v-btn rounded outlined @click.stop="copyShareInfoToClipboard()" class="ml-2"><v-icon small>mdi-link-variant</v-icon>共有リンクをコピー</v-btn></pre>
                <pre v-else>アクセスは許可されていません。</pre>
              </template>
            </v-card-text>
          </v-card>
          <v-dialog max-width="400px" persistent v-model="crud.shareInfo.edit">
            <v-form ref="shareInfo" v-model="crud.shareInfo.valid">
              <v-card>
                <v-card-title>共有設定の編集</v-card-title>
                <v-divider />
                <v-card-text class="pt-3">
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-autocomplete v-model="crud.shareInfo.restore.end_user_id" :items="crud.shareInfo?.end_users" item-text="end_company_name" item-value="id" label="アクセスできるクライアント" cache-items hide-details hide-no-data clearable></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select v-model="crud.shareInfo.restore.guest_shared" item-text="text" item-value="value" :items="crud.shareInfo?.guest_shared_options" label="ゲストアクセス" persistent-hint single-line></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="cancelEditShareInfo()">キャンセル</v-btn>
                  <v-btn class="primary" @click="updateShareInfo()" :disabled="!crud.shareInfo.valid">保存</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-container>
      </v-tab-item>
    </v-tabs>
    <v-container id="timeline">
      <v-row>
        <v-col>
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <!-- Timeline -->
            <div class="changed-status">
              <v-chip small color="secondary">メッセージ</v-chip>
            </div>
            <template v-for="(item, i) in direction.direction_comments">
              <v-timeline-item :key="i" v-if="item.comment" :id="'direction_comment-' + item.id" :right="item.submit_login.is_admin_user" :left="!item.submit_login.is_admin_user" :class="{end_user: item.submit_login.is_end_user, admin_user: item.submit_login.is_admin_user, guest_user: item.submit_login.is_guest_user}">
                <template v-slot:icon>
                  <LoginAvatar :user="item.submit_login" :size="($vuetify.breakpoint.smAndDown ? 32 : 48)" />
                </template>
                <template v-slot:opposite>
                  <span class="d-block text-caption">
                    {{ item.create_at | moment(defaultDateFormat) }}
                  </span>
                  <span>{{ item.submit_login.name }}</span>
                </template>
                <v-card class="elevation-2">
                  <v-card-text class="pa-3">
                    <div class="text-caption mb-2 d-md-none">
                      <span class="mr-2">{{ item.create_at | moment(defaultDateFormat) }}</span>
                      <span>{{ item.submit_login.name }}</span>
                    </div>
                    <span v-text="item.comment" v-linkified></span>
                    <v-layout v-if="loginUserInfo.is_admin_user">
                      <v-spacer />
                      <confirm-dialog @accept="deleteDirectionComment(item)">
                        <template v-slot:title>このコメントを削除しますか？</template>
                        <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
                        <template v-slot:activator="{ on }">
                          <v-icon small class="ml-2" @click.stop="on">mdi-delete-forever</v-icon>
                        </template>
                      </confirm-dialog>
                    </v-layout>
                  </v-card-text>
                  <template v-if="item.file_attachments && item.file_attachments.length">
                    <v-card-actions class="px-4 py-1">
                      <v-chip-group column>
                        <template v-for="file in item.file_attachments">
                          <FileAttachmentChip :key="file.id" :file="file"></FileAttachmentChip>
                        </template>
                      </v-chip-group>
                      <v-spacer />
                    </v-card-actions>
                  </template>
                  <v-divider />
                  <v-card-text class="px-4 py-2">
                    <emoji-reaction :direction-comment-id="item.id"/>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </template>
            <!-- Action Button -->
            <div class="add text-center">
              <v-btn @click="showDirectionCommentDialog({ direction_id: direction.id })" x-large fab dark color="primary" elevation="5"><v-icon>mdi-comment-plus</v-icon></v-btn>
            </div>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
    <!-- 依頼情報 -->
    <v-navigation-drawer app clipped right width="190" mobile-breakpoint="700" id="sub-nav">
      <v-list class="pa-0">
        <v-list-item v-if="direction.direction_delivery_date">
          <v-list-item-content>
            <v-list-item-subtitle class="mb-2">
              納品予定日
            </v-list-item-subtitle>
            <v-list-item-title class="text-h6">
              {{ direction.direction_delivery_date | moment("YYYY/M/D(ddd)") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="mb-2">
              進捗率
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon><v-icon small v-bind="attrs" v-on="on">mdi-help-circle</v-icon></v-btn>
                </template>
                <span>進捗率の目安です。<br>デザイン完了：50%<br>仮アップ完了：75%<br>本アップ完了：100%</span>
              </v-tooltip>
            </v-list-item-subtitle>
            <v-list-item-title>
              <v-progress-circular :rotate="-90" :size="100" :width="12.5" :value="direction.progress_percentage" color="primary">
                <span class="text-h4">{{ direction.progress_percentage }}</span><span>%</span>
              </v-progress-circular>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-stepper v-model="status_stepper_step" flat vertical non-linear v-for="(item, i) in AllStatus" :key="i">
              <v-stepper-step v-if="loginUserInfo.is_admin_user" editable :step="item.step" @click="editStatusInfo(item)">
                {{ item.status }}
              </v-stepper-step>
              <v-stepper-step v-else :step="item.step">
                {{ item.status }}
              </v-stepper-step>
            </v-stepper>
          </v-list-item-content>
          <v-dialog max-width="400px" persistent v-model="crud.statusInfo.edit">
            <v-form ref="statusInfo" v-model="crud.statusInfo.valid">
              <v-card>
                <v-card-title>状況の編集</v-card-title>
                <v-divider />
                <v-card-text class="pt-3">
                  <v-row dense>
                    <v-col cols="12" md="12">
                      <v-select v-model="crud.statusInfo.restore.direction_status_mst_id" item-text="status" item-value="id" :items="AllStatus" label="状況" persistent-hint single-line clearable></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="cancelEditStatusInfo()">キャンセル</v-btn>
                  <v-btn class="primary" @click="updateStatusInfo()" :disabled="!crud.statusInfo.valid">保存</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- ダイアログ用スロット -->
    <componentSlot :is="componentSlot.component" :params="componentSlot.params" v-on="componentSlot.event" />

  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import DirectionCommentDialog from '@/components/DirectionCommentDialog.vue'
import DirectionDataoffer from '@/components/DirectionDataoffer.vue'
import DirectionSite from '@/components/DirectionSite.vue'
import DirectionFile from '@/components/DirectionFile.vue'
import EmojiReaction from '@/components/EmojiReaction.vue'
import axios from '@/plugins/axios'
import FileAttachmentChip from '@/components/FileAttachmentChip.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  name: 'Direction',
  components: {
    ConfirmDialog,
    DirectionCommentDialog,
    DirectionDataoffer,
    DirectionSite,
    DirectionFile,
    FileAttachmentChip,
    EmojiReaction,
    LoginAvatar
  },
  data: function () {
    return {
      isLoading: false,
      status_stepper_step: 0,
      selectedTabs: 0,
      infoPanels: [],
      crud: {
        statusInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_status_mst_id: ''
          }
        },
        customerInfo: {
          edit: false,
          valid: false,
          restore: {
            business_person_id: '',
            direction_director_person_id: '',
            agent_id: '',
            direction_customer_person_name: '',
            direction_customer_person_name2: '',
            direction_customer_mailaddress: '',
            direction_customer_tel: ''
          }
        },
        basicInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_type: '',
            direction_type2: '',
            direction_site_num: '',
            direction_delivery_date: '',
            direction_sp: '',
            direction_deadline: '',
            direction_interview: '',
            direction_cms: ''
          }
        },
        systemInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_domain_get: '',
            direction_domain: '',
            direction_ssl: '',
            direction_server_manage: '',
            direction_form_mailaddress: '',
            direction_server_information: ''
          }
        },
        conceptInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_purpose: '',
            direction_content: '',
            direction_target_sex: [],
            direction_target_age: [],
            direction_target_type: [],
            direction_target: '',
            direction_keyword: '',
            direction_sub_keyword: '',
            direction_reference: '',
            direction_site_color: [],
            direction_site_tone: [],
            direction_site_tone_note: ''
          }
        },
        noticeInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_notices: ''
          }
        },
        reviewInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_approval: '',
            direction_url: ''
          }
        },
        deliveryInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_production_url: ''
          }
        },
        contactInfo: {
          edit: false,
          valid: false,
          restore: {
            direction_board_mail_to: [],
            direction_board_mail_cc: []
          }
        },
        shareInfo: {
          edit: false,
          valid: false,
          restore: {
            end_user_id: '',
            guest_shared: ''
          },
          end_users: [],
          guest_shared_options: [
            { value: 0, text: 'ゲストアクセスを許可しない' },
            { value: 1, text: 'ゲストアクセスを許可する' }
          ]
        }
      },
      componentSlot: { // ステータス変更コンポーネント読み込み用
        component: '',
        params: {
          direction: null
        },
        event: {
          close: this.hideStatusChangeDialog,
          reload: this.readDataFromAPI
        }
      },
      direction: {} // 案件情報
    }
  },
  created: function () {
    console.log(this.$route.params.id)
    this.direction.id = this.$route.params.id
    this.readDataFromAPI()
    this.readEndUserFromAPI()
  },
  methods: {
    editStatusInfo (item) {
      this.crud.statusInfo.restore.direction_status_mst_id = this.direction.direction_status_mst_id
      if (item) {
        this.crud.statusInfo.restore.direction_status_mst_id = item.id
      }
      this.crud.statusInfo.edit = true
    },
    updateStatusInfo () {
      const _this = this
      if (_this.$refs.statusInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_status_mst_id', _this.crud.statusInfo.restore.direction_status_mst_id ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', '状況を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.statusInfo.edit = false
          })
      }
    },
    cancelEditStatusInfo () {
      this.crud.statusInfo.edit = false
      this.status_stepper_step = this.direction?.direction_status?.step ?? 0
    },
    editCustomerInfo () {
      this.crud.customerInfo.restore.business_person_id = this.direction.business_person_id
      this.crud.customerInfo.restore.direction_director_person_id = this.direction.direction_director_person_id
      this.crud.customerInfo.restore.agent_id = this.direction.agent_id
      this.crud.customerInfo.restore.direction_customer_person_name = this.direction.direction_customer_person_name
      this.crud.customerInfo.restore.direction_customer_person_name2 = this.direction.direction_customer_person_name2
      this.crud.customerInfo.restore.direction_customer_mailaddress = this.direction.direction_customer_mailaddress
      this.crud.customerInfo.restore.direction_customer_tel = this.direction.direction_customer_tel
      this.crud.customerInfo.edit = true
    },
    updateCustomerInfo () {
      const _this = this
      if (_this.$refs.customerInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('business_person_id', _this.crud.customerInfo.restore.business_person_id ?? '')
        formData.append('direction_director_person_id', _this.crud.customerInfo.restore.direction_director_person_id ?? '')
        formData.append('agent_id', _this.crud.customerInfo.restore.agent_id ?? '')
        formData.append('direction_customer_person_name', _this.crud.customerInfo.restore.direction_customer_person_name ?? '')
        formData.append('direction_customer_person_name2', _this.crud.customerInfo.restore.direction_customer_person_name2 ?? '')
        formData.append('direction_customer_mailaddress', _this.crud.customerInfo.restore.direction_customer_mailaddress ?? '')
        formData.append('direction_customer_tel', _this.crud.customerInfo.restore.direction_customer_tel ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', 'お客様情報を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.customerInfo.edit = false
          })
      }
    },
    cancelEditCustomerInfo () {
      this.crud.customerInfo.edit = false
    },
    editBasicInfo () {
      this.crud.basicInfo.restore.direction_type = this.direction.direction_type
      this.crud.basicInfo.restore.direction_type2 = this.direction.direction_type2
      this.crud.basicInfo.restore.direction_site_num = this.direction.direction_site_num
      this.crud.basicInfo.restore.direction_delivery_date = this.direction.direction_delivery_date
      this.crud.basicInfo.restore.direction_sp = this.direction.direction_sp
      this.crud.basicInfo.restore.direction_deadline = this.direction.direction_deadline
      this.crud.basicInfo.restore.direction_interview = this.direction.direction_interview
      this.crud.basicInfo.restore.direction_cms = this.direction.direction_cms
      this.crud.basicInfo.edit = true
    },
    updateBasicInfo () {
      const _this = this
      if (_this.$refs.basicInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_type', _this.crud.basicInfo.restore.direction_type ?? '')
        formData.append('direction_type2', _this.crud.basicInfo.restore.direction_type2 ?? '')
        formData.append('direction_site_num', _this.crud.basicInfo.restore.direction_site_num ?? '')
        formData.append('direction_delivery_date', _this.crud.basicInfo.restore.direction_delivery_date ?? '')
        formData.append('direction_sp', _this.crud.basicInfo.restore.direction_sp ?? '')
        formData.append('direction_deadline', _this.crud.basicInfo.restore.direction_deadline ?? '')
        formData.append('direction_interview', _this.crud.basicInfo.restore.direction_interview ?? '')
        formData.append('direction_cms', _this.crud.basicInfo.restore.direction_cms ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', '基本情報を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.basicInfo.edit = false
          })
      }
    },
    cancelEditBasicInfo () {
      this.crud.basicInfo.edit = false
    },
    editSystemInfo () {
      this.crud.systemInfo.restore.direction_domain_get = this.direction.direction_domain_get
      this.crud.systemInfo.restore.direction_domain = this.direction.direction_domain
      this.crud.systemInfo.restore.direction_ssl = this.direction.direction_ssl
      this.crud.systemInfo.restore.direction_server_manage = this.direction.direction_server_manage
      this.crud.systemInfo.restore.direction_form_mailaddress = this.direction.direction_form_mailaddress
      this.crud.systemInfo.restore.direction_server_information = this.direction.direction_server_information
      this.crud.systemInfo.edit = true
    },
    updateSystemInfo () {
      const _this = this
      if (_this.$refs.systemInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_domain_get', _this.crud.systemInfo.restore.direction_domain_get ?? '')
        formData.append('direction_domain', _this.crud.systemInfo.restore.direction_domain ?? '')
        formData.append('direction_ssl', _this.crud.systemInfo.restore.direction_ssl ?? '')
        formData.append('direction_server_manage', _this.crud.systemInfo.restore.direction_server_manage ?? '')
        formData.append('direction_form_mailaddress', _this.crud.systemInfo.restore.direction_form_mailaddress ?? '')
        formData.append('direction_server_information', _this.crud.systemInfo.restore.direction_server_information ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', 'システムを更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.systemInfo.edit = false
          })
      }
    },
    cancelEditSystemInfo () {
      this.crud.systemInfo.edit = false
    },
    editConceptInfo () {
      this.crud.conceptInfo.restore.direction_purpose = this.direction.direction_purpose
      this.crud.conceptInfo.restore.direction_content = this.direction.direction_content
      this.crud.conceptInfo.restore.direction_target_sex = this.direction.direction_target_sex
      this.crud.conceptInfo.restore.direction_target_age = this.direction.direction_target_age
      this.crud.conceptInfo.restore.direction_target_type = this.direction.direction_target_type
      this.crud.conceptInfo.restore.direction_target = this.direction.direction_target
      this.crud.conceptInfo.restore.direction_keyword = this.direction.direction_keyword
      this.crud.conceptInfo.restore.direction_sub_keyword = this.direction.direction_sub_keyword
      this.crud.conceptInfo.restore.direction_reference = this.direction.direction_reference
      this.crud.conceptInfo.restore.direction_site_color = this.direction.direction_site_color
      this.crud.conceptInfo.restore.direction_site_tone = this.direction.direction_site_tone
      this.crud.conceptInfo.restore.direction_site_tone_note = this.direction.direction_site_tone_note
      this.crud.conceptInfo.edit = true
    },
    updateConceptInfo () {
      const _this = this
      if (_this.$refs.conceptInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_purpose', _this.crud.conceptInfo.restore.direction_purpose ?? '')
        formData.append('direction_content', _this.crud.conceptInfo.restore.direction_content ?? '')
        if (_this.crud.conceptInfo.restore.direction_target_sex) {
          for (const value of _this.crud.conceptInfo.restore.direction_target_sex) {
            formData.append('direction_target_sex[]', value)
          }
        }
        if (_this.crud.conceptInfo.restore.direction_target_age) {
          for (const value of _this.crud.conceptInfo.restore.direction_target_age) {
            formData.append('direction_target_age[]', value)
          }
        }
        if (_this.crud.conceptInfo.restore.direction_target_type) {
          for (const value of _this.crud.conceptInfo.restore.direction_target_type) {
            formData.append('direction_target_type[]', value)
          }
        }
        formData.append('direction_target', _this.crud.conceptInfo.restore.direction_target ?? '')
        formData.append('direction_keyword', _this.crud.conceptInfo.restore.direction_keyword ?? '')
        formData.append('direction_sub_keyword', _this.crud.conceptInfo.restore.direction_sub_keyword ?? '')
        formData.append('direction_reference', _this.crud.conceptInfo.restore.direction_reference ?? '')
        if (_this.crud.conceptInfo.restore.direction_site_color) {
          for (const value of _this.crud.conceptInfo.restore.direction_site_color) {
            formData.append('direction_site_color[]', value)
          }
        }
        if (_this.crud.conceptInfo.restore.direction_site_tone) {
          for (const value of _this.crud.conceptInfo.restore.direction_site_tone) {
            formData.append('direction_site_tone[]', value)
          }
        }
        formData.append('direction_site_tone_note', _this.crud.conceptInfo.restore.direction_site_tone_note ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', 'コンセプト・デザインを更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.conceptInfo.edit = false
          })
      }
    },
    cancelEditConceptInfo () {
      this.crud.conceptInfo.edit = false
    },
    editNoticeInfo () {
      this.crud.noticeInfo.restore.direction_notices = this.direction.direction_notices
      this.crud.noticeInfo.edit = true
    },
    updateNoticeInfo () {
      const _this = this
      if (_this.$refs.noticeInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_notices', _this.crud.noticeInfo.restore.direction_notices ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', '特記事項を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.noticeInfo.edit = false
          })
      }
    },
    cancelEditNoticeInfo () {
      this.crud.noticeInfo.edit = false
    },
    editReviewInfo () {
      this.crud.reviewInfo.restore.direction_approval = this.direction.direction_approval
      this.crud.reviewInfo.restore.direction_url = this.direction.direction_url
      this.crud.reviewInfo.edit = true
    },
    updateReviewInfo () {
      const _this = this
      if (_this.$refs.reviewInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_approval', _this.crud.reviewInfo.restore.direction_approval ?? '')
        formData.append('direction_url', _this.crud.reviewInfo.restore.direction_url ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', '仮アップ情報を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.reviewInfo.edit = false
          })
      }
    },
    cancelEditReviewInfo () {
      this.crud.reviewInfo.edit = false
    },
    approvalReviewInfo () {
      const _this = this
      const formData = new FormData()
      formData.append('_method', 'PUT')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}/approval`, formData)
        .then(() => {
          _this.$store.commit('setFlashSuccess', '仮アップサイトを承認しました。')
          _this.readDataFromAPI()
        })
        .catch(err => {
          _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
        .finally(() => {
          _this.crud.reviewInfo.edit = false
        })
    },
    editDeliveryInfo () {
      this.crud.deliveryInfo.restore.direction_production_url = this.direction.direction_production_url
      this.crud.deliveryInfo.edit = true
    },
    updateDeliveryInfo () {
      const _this = this
      if (_this.$refs.deliveryInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_production_url', _this.crud.deliveryInfo.restore.direction_production_url ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', '本アップ情報を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.deliveryInfo.edit = false
          })
      }
    },
    cancelEditDeliveryInfo () {
      this.crud.deliveryInfo.edit = false
    },
    editContactInfo () {
      this.crud.contactInfo.restore.direction_board_mail_to = this.direction.direction_board_mail_to
      this.crud.contactInfo.restore.direction_board_mail_cc = this.direction.direction_board_mail_cc
      this.crud.contactInfo.edit = true
    },
    updateContactInfo () {
      const _this = this
      if (_this.$refs.contactInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_board_mail', 'DUMMY')
        if (_this.crud.contactInfo.restore.direction_board_mail_to) {
          for (const value of _this.crud.contactInfo.restore.direction_board_mail_to) {
            formData.append('direction_board_mail_to[]', value)
          }
        }
        if (_this.crud.contactInfo.restore.direction_board_mail_cc) {
          for (const value of _this.crud.contactInfo.restore.direction_board_mail_cc) {
            formData.append('direction_board_mail_cc[]', value)
          }
        }
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', 'メッセージ通知先を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.contactInfo.edit = false
          })
      }
    },
    cancelEditContactInfo () {
      this.crud.contactInfo.edit = false
    },
    editShareInfo () {
      this.crud.shareInfo.restore.end_user_id = this.direction.end_user_id
      this.crud.shareInfo.restore.guest_shared = this.direction.guest_shared
      this.crud.shareInfo.edit = true
    },
    updateShareInfo () {
      const _this = this
      if (_this.$refs.shareInfo.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('end_user_id', _this.crud.shareInfo.restore.end_user_id ?? '')
        formData.append('guest_shared', _this.crud.shareInfo.restore.guest_shared ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', '共有設定を更新しました。')
            _this.readDataFromAPI()
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            _this.crud.shareInfo.edit = false
          })
      }
    },
    cancelEditShareInfo () {
      this.crud.shareInfo.edit = false
    },
    copyShareInfoToClipboard () {
      const self = this
      const value = `◇共有リンク：${this.directionShareLink}\n◇閲覧パスワード：${this.direction.guest_password}`
      navigator.clipboard.writeText(value)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'クリップボードに「共有リンク」と「閲覧パスワード」をコピーしました。')
        })
        .catch(e => {
          console.error(e)
        })
    },
    showDirectionCommentDialog: function (option) {
      this.componentSlot.component = 'DirectionCommentDialog'
      this.componentSlot.params = option
      this.componentSlot.params.direction = this.direction
      return false
    },
    hideStatusChangeDialog: function () {
      this.componentSlot.component = ''
      this.componentSlot.props = {}
    },
    readDataFromAPI: function () {
      this.isLoading = true
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${this.direction.id}`)
        .then(res => {
          this.direction = res.data
          this.status_stepper_step = this.direction?.direction_status?.step ?? 0
          document.title = document.title.replace('制作案件詳細', this.direction.direction_name)
          if (this.$refs.direction_file) {
            this.$refs.direction_file.readDataFromAPI()
          }
          if (this.$refs.direction_site) {
            this.$refs.direction_site.readDataFromAPI()
          }

          // アンカーリンクへスクロール
          this.$nextTick(() => {
            const hash = this.$route.hash
            if (hash) {
              const element = document.querySelector(hash)
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
              }
            }
          })
        })
        .catch(err => {
          console.log(err)
          if (err.response.status === 403) {
            this.$router.push({ name: 'login:direction', params: { id: this.direction.id } })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    readEndUserFromAPI: function () {
      if (this.loginUserInfo.is_admin_user) {
        this.isLoading = true
        axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user`, {
          params: {
            quicksearch: 'in_contract',
            per_page: 1000
          }
        })
          .then(res => {
            this.crud.shareInfo.end_users = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    deleteDirectionComment (item) {
      const self = this
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.direction.id}/direction_comment/${item.id}`)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'コメントを削除しました。')
          self.readDataFromAPI()
        })
        .catch(err => {
          self.$store.commit('setFlashError', '削除することができませんでした。')
          console.log(err)
        })
    },
    // 固定化した切り替えタブを押した場合にページトップへ移動する
    onFixedTabChange () {
      this.$nextTick(() => {
        this.pageTopFixedTabs()
      })
    },
    pageTopFixedTabs () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    },
    defaultDateFormat: function () {
      return this.$store.getters.defaultDateFormat
    },
    AllStatus: function () {
      return this.$store.getters.getAllDirectionStatus
    },
    directionShareLink: function () {
      const path = this.$router.resolve({
        name: 'guest:direction_show',
        params: this.$route.params
      }).href
      return window.location.origin + path
    },
    visibleDirectionSiteApprovalMenu: function () {
      return this.loginUserInfo.is_admin_user !== true && this.direction.can_direction_site_approval === true
    },
    visibleDirectionApprovalMenu: function () {
      return this.loginUserInfo.is_admin_user !== true && this.direction.can_direction_approval === true
    }
  }
}
</script>

<style lang="scss" scoped>

.container {
  padding: 20px;
}

.v-toolbar__title {
  white-space: inherit;
}

::v-deep .v-toolbar__content {
  height: 60px;
}

.whitespace-pre-wrap {
  display: inline-block;
  white-space: pre-wrap;
}

.v-stepper--vertical .v-stepper__step{
  padding: 12px 5px;
}
.v-stepper--vertical .v-stepper__content{
  margin: 0px 0px 0px 16px !important;
  padding: 6px;
}

.v-sheet.v-stepper{
  padding-bottom: 0;
}

.v-card__text{
  white-space: pre-line;
  font-size: 1rem;
}

.v-expansion-panel-header {
  font-size: 105%;
}

::v-deep td.point{
  width: 70px;
}

.v-timeline {
  padding-top: 0;
}

.changed-status{
  margin: 16px 0 40px 0;
  position: relative;
  &:before{
    content: "";
    display: block;
    height: 1px;
    border-top: 1px dotted #000;
  }
  span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.add{
  position: sticky;
  bottom: 0;
  padding: 20px 0 10px 0;
  z-index: 50;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,.8) 60%, rgba(255,255,255,0) 100%);
  width: 100%;
}

::v-deep .v-input--is-readonly {
  .v-input__slot {
    cursor: default;
    .v-input--selection-controls__input {
      pointer-events: none;
    }
    .v-label {
      cursor: text;
      user-select: text;
    }
  }
}

</style>

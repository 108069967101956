<template>
<v-alert border="left" colored-border type="info" elevation="1" dense>
    <slot></slot>
</v-alert>
</template>

<script>
export default {
  name: 'InformationBox'
}
</script>

<template>
    <v-dialog persistent v-model="isShow" max-width="800px">
      <v-card>
        <v-card-title>新規依頼</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="form.valid">
          <!-- <v-container class=""> -->
            <v-row dense class="mb-0">
              <v-col md="4">
                <v-menu v-model="isShow_release_date_picker" :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :rules="[inputRules.required]" label="公開希望日" class="required" prepend-icon="mdi-calendar" readonly v-model="form.release_date" v-bind="attrs" v-on="on" hide-details="auto"></v-text-field>
                  </template>
                  <v-date-picker v-model="form.release_date" @input="isShow_release_date_picker = false" :min="release_date_min" :max="release_date_max" :allowed-dates="allowedDates" first-day-of-week="1" no-title scrollable :day-format="date => new Date(date).getDate()"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="4">
                <v-select label="公開希望時間" prepend-icon="mdi-clock-time-four-outline" v-model="form.release_time" :items="release_time_options" hide-details></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field :rules="[inputRules.required]" label="件名" class="required" v-model="form.subject" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea :rules="[inputRules.required]" label="依頼内容" class="required mt-5" style="padding-bottom: 30px;" rows="5" v-model="form.request_content" hide-details="auto" outlined auto-grow></v-textarea>
                <v-row>
                  <v-col>
                    <v-btn @click="addImageMemo" class="mr-3"><v-icon right class="mr-1">mdi-file-image-plus</v-icon>画面メモの追加</v-btn>
                    <a href="https://aun.app/qqnpm" target="_blank" rel="noopener" class="text-decoration-none d-inline-block" style="font-size: 12px; color: rgba(0, 0, 0, 0.87);"><v-icon style="font-size: 14px;">mdi-help-circle</v-icon>使い方をみる</a>
                  </v-col>
                  <v-col>
                    <v-file-input :rules="[rules.filesRule]" v-model="form.files" class="my-0 py-0" small-chips multiple counter show-size label="ファイル添付" hint="複数選択可" persistent-hint ></v-file-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="this.$store.getters.loginUserInfo.is_admin_user">
                <v-select :rules="[rules.selectedRule]" label="クライアント担当者" class="required" v-model="form.representative" :items="accounts" item-text="name" return-object hide-details="auto" ></v-select>
              </v-col>
            </v-row>

            <v-row no-gutters class="align-end mt-6">
              <v-col cols="12" class="text-right">
                <v-btn @click="showMembers" class="" :disabled="!withoutRepresentative.length"><v-icon left>mdi-account-multiple-plus</v-icon>依頼関係者</v-btn>
              </v-col>
              <v-expand-transition>
                <v-col cols="12" class="pt-3" v-show="isShow_member">
                  <v-card outlined>
                    <v-list rounded>
                  <v-list-item-group v-model="form.secondary_submit_login" multiple>
                    <v-row dense>
                      <template v-for="(member, i) in withoutRepresentative">
                        <v-col cols="12" md="4" :key="i">
                          <v-list-item  dense :value="member.id">
                            <template v-slot:default="{ active }">
                              <v-list-item-avatar size="26" v-if="member.photo"><v-img :src="member.photo.file_url"></v-img></v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{member.name}}</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-checkbox dense :input-value="active" color=""></v-checkbox>
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                        </v-col>
                      </template>
                    </v-row>
                  </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-expand-transition>
            </v-row>

          <!-- </v-container> -->
          </v-form>
        </v-card-text>
          <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="" text @click="close()">キャンセル</v-btn>
          <v-btn v-promise-btn @click="addRequest()" color="primary"><v-icon small left>mdi-checkbox-marked-circle-plus-outline</v-icon>追加</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
        <!-- <v-overlay :value="isLoading" absolute>
          <v-progress-circular indeterminate size="64" />
        </v-overlay> -->
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
import moment from 'moment'

export default {
  name: 'newRequest',
  props: {
    endId: String
  },
  data: () => ({
    isShow: false,
    isShow_release_date_picker: false,
    isShow_member: false,
    rules: {
      selectedRule: value => !!value.id || '必ず選択してください',
      filesRule: files => !files || !files.some(file => file.size > (20 * 1000 * 1000)) || 'サイズが大きすぎます'
    },
    form: {
      release_date: '',
      release_time: '',
      subject: '',
      request_content: '',
      files: null,
      representative: {},
      secondary_submit_login: []
    },
    restoreForm: {
      release_date: '',
      release_time: '',
      subject: '',
      request_content: '',
      files: null,
      representative: {},
      secondary_submit_login: []
    },
    release_date_min: null,
    release_date_max: null,
    release_time_options: [
      { text: '指定なし', value: '' },
      { text: '10:00', value: '10:00:00' },
      { text: '11:00', value: '11:00:00' },
      { text: '12:00', value: '12:00:00' },
      { text: '13:00', value: '13:00:00' },
      { text: '14:00', value: '14:00:00' },
      { text: '15:00', value: '15:00:00' },
      { text: '16:00', value: '16:00:00' },
      { text: '17:00', value: '17:00:00' },
      { text: '18:00', value: '18:00:00' }
    ],
    accounts: []
  }),
  created () {
    // 公開希望日の選択範囲を設定
    this.release_date_min =
        this.calcBusinessDays(this.$store.getters.getAdminCompanyHoliday.release_days_min ?? 0)
    this.release_date_max =
        this.calcBusinessDays(this.$store.getters.getAdminCompanyHoliday.release_days_max ?? 365)

    // 公開希望時間オプションを動的に生成
    const startOfficeHours = this.$store.getters.getAdminCompanyHoliday.start_office_hours
    const endOfficeHours = this.$store.getters.getAdminCompanyHoliday.end_office_hours
    if (startOfficeHours && endOfficeHours) {
      this.createReleaseTimeOptions(startOfficeHours, endOfficeHours)
    }
  },
  computed: {
    selectAccount: function () {
      var rtn = []
      for (const elm of this.accounts) {
        const items = {}
        items.text = elm.name
        items.value = elm.id
        rtn.push(items)
      }
      return rtn
    },
    withoutRepresentative: function () {
      var rtn = []
      for (const elm of this.accounts) {
        if (elm.id !== this.$store.getters.loginUserInfo.id && elm.id !== this.form.representative.id) {
          rtn.push(elm)
        }
      }
      return rtn
    }
  },
  methods: {
    calcBusinessDays (days) {
      const weeklyHoliday = this.$store.getters.getAdminCompanyHoliday.weekly_holiday
      const regularHoliday = this.$store.getters.getAdminCompanyHoliday.regular_holiday

      const currentDate = moment()
      let addedDays = 0

      while (addedDays < days) {
        currentDate.add(1, 'days')

        if (weeklyHoliday.includes(currentDate.day())) {
          continue
        }

        if (regularHoliday.includes(currentDate.format('YYYY-MM-DD'))) {
          continue
        }

        addedDays++
      }

      return currentDate.format('YYYY-MM-DD')
    },
    createReleaseTimeOptions: function (startOfficeHours, endOfficeHours) {
      const start = moment(startOfficeHours, 'HH:mm:ss')
      const end = moment(endOfficeHours, 'HH:mm:ss')
      const diff = end.diff(start, 'hours')
      const options = [{ text: '指定なし', value: '' }]
      for (let i = 0; i <= diff; i++) {
        const time = start.add(i === 0 ? 0 : 1, 'hours').format('HH:mm:ss')
        options.push({ text: time.slice(0, 5), value: time })
      }
      this.release_time_options = options
    },
    allowedDates: function (val) {
      // 「定休日」は選択不可
      const dayOfWeek = new Date(val).getDay()
      if (this.$store.getters.getAdminCompanyHoliday.weekly_holiday.indexOf(dayOfWeek) !== -1) {
        return false
      }
      // 「その他の休日」は選択不可
      if (this.$store.getters.getAdminCompanyHoliday.regular_holiday.indexOf(val) !== -1) {
        return false
      }
      return true
    },
    addRequest: function () {
      console.info('send')
      const _this = this
      if (_this.$refs.form.validate()) {
        const formData = new FormData()
        if (this.endId) {
          formData.append('end_user_id', this.endId)
        }
        formData.append('_method', 'POST')
        formData.append('release_date', _this.form.release_date)
        formData.append('release_time', _this.form.release_time)
        formData.append('subject', _this.form.subject)
        formData.append('request_content', _this.form.request_content)
        formData.append('representative', this.$store.getters.loginUserInfo.is_admin_user ? _this.form.representative.id : this.$store.getters.loginUserInfo.id)
        if (_this.form.files) {
          for (const file of _this.form.files) {
            formData.append('files[]', file, file.name)
          }
        }
        if (_this.form.secondary_submit_login) {
          for (const loginId of _this.form.secondary_submit_login) {
            formData.append('secondary_submit_login[]', loginId)
          }
        }
        formData.append('point_allocate_date', moment().format('YYYY-MM-DD'))
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request`, formData)
            .then(res => {
              console.log(res.data)
              _this.$store.commit('setFlashSuccess', '依頼が作成されました。')
              if (_this.endId) {
                _this.$router.push({ name: 'admin:request_show', params: { id: res.data.id } })
              } else {
                _this.$router.push({ name: 'client:request_show', params: { id: res.data.id } })
              }
              resolve(res)
              _this.close()
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              console.log(err)
              reject(err)
              // _this.close()
            })
        })
      }
    },
    open: function () {
      this.isShow = true
    },
    close: function () {
      this.isShow = false
      this.isShow_member = !this.isShow_member
      // this.accounts = []
      this.form = this.restoreForm
    },
    showMembers: function () {
      this.isShow_member = !this.isShow_member
    },
    getClientLoginAccounts: function () {
      var id = (this.endId) ? this.endId : this.$store.getters.loginUserInfo.end_user_id
      if (id === null) return
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${id}`)
        .then(res => {
          this.accounts = res.data.logins
        })
        .catch(err => {
          console.log(err)
        })
    },
    addImageMemo: function () {
      const _self = this
      let _vWin = null
      window.addEventListener('message', function addMessageFunc (event) {
        const _message = event.data
        if (typeof _message === 'string' || _message instanceof String) {
          if (_self.form.request_content.length !== '') {
            _self.form.request_content += '\n'
          }
          _self.form.request_content += event.data
          _vWin.close()
          this.removeEventListener('message', addMessageFunc)
        }
      })
      _vWin = window.open(_self.$store.getters.getVisualRelayUrl, '_vWin')
      _vWin.focus()
    }
  },
  watch: {
    $props: {
      handler () {
        this.getClientLoginAccounts() // load login accounts individually
      },
      deep: true,
      immediate: true
    },
    'form.representative': {
      handler () {
        this.form.secondary_submit_login = []
      }
    }
  }
}
</script>

import axios from 'axios'
import firebase from '@/plugins/firebase'

axios.interceptors.request.use(async config => {
  const idToken = await firebase.auth().currentUser.getIdToken()
  config.headers.common.Authorization = `Bearer ${idToken}`
  return config
}, (error) => {
  return Promise.reject(error)
})

export default axios

<template>

  <div id="holiday" :class="{editing: isEdit}">
    <v-toolbar elevation="0">
      <v-spacer />
      <v-scroll-y-transition>
        <v-alert v-if="isEdit" dense type="info" class="mb-0" color="primary">
          編集モード中：変更内容はリアルタイムに適用されます
        </v-alert>
      </v-scroll-y-transition>
      <v-spacer />
      <v-switch v-model="isEdit" label="編集モード" hide-details=""></v-switch>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col>
          <v-card style="flex:1" class="cal">
            <v-card-title>その他の休日<popup-hint>カレンダークリックで休日を設定・解除が行えます</popup-hint></v-card-title>
            <v-divider />
            <v-card-text class="pt-0">
              <v-toolbar flat height="50">
                <v-toolbar-title v-if="calHoliday">{{ calHoliday.title }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items v-if="calHoliday">
                  <v-btn class="dense" text @click="setToday">今月</v-btn>
                  <v-btn fab text small color="" @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
                  <v-btn fab text small color="" @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-sheet height="460">
              <v-calendar class="fullCal" ref="calHoliday" v-model="focus" @change="updateCalendar" color="primary" :weekdays="weekdays" :events="holiday" :event-more="false" @click:day="eventClick">
                <template v-slot:day="{ weekday }">
                  <span v-if="isRegularClosed(weekday)" class="regularHoliday">定休日</span>
                </template>
                  <template v-slot:event="{}">
                    <span class="">休日</span>
                  </template>
                <template v-slot:day-label="{ day, future, present}">
                  <span :class="{passed: !future, today: present}">{{day}}</span>
                </template>
              </v-calendar>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <v-card class="start-end">
              <v-card-title>
                サポート対応時間
                <popup-hint>公開希望時間の選択範囲を設定できます。</popup-hint>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-0">
                <h4>開始時間</h4>
                <v-text-field :readonly="!isEdit" v-model="start" @change="sendDataToAPI()" :max="end" type="time" hide-details dense></v-text-field>
                <h4>終了時間</h4>
                <v-text-field :readonly="!isEdit" v-model="end" @change="sendDataToAPI()" :min="start" type="time" hide-details dense></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="12">
            <v-card>
              <v-card-title>
                定休日
                <popup-hint>チェックを入れた曜日は公開希望日として選択できなくなります。</popup-hint>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-0">
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="月曜日" :value="1" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="火曜日" :value="2" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="水曜日" :value="3" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="木曜日" :value="4" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="金曜日" :value="5" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="土曜日" :value="6" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060"  :readonly="!isEdit" v-model="regularClosed" label="日曜日" :value="0" hide-details dense></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="12">
            <v-card>
              <v-card-title>
                公開希望日
                <popup-hint>選択可能な公開日の範囲を設定できます。</popup-hint>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-0">
                <h4>選択可能最小値</h4>
                <v-row align="center">
                  <v-col>
                    <v-text-field
                        :readonly="!isEdit"
                        v-model="releaseDaysMin"
                        @change="changeReleaseDaysMin()"
                        :min="0"
                        :max="releaseDaysMax"
                        type="number"
                        hide-details
                        dense
                    />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <span>営業日後</span>
                  </v-col>
                </v-row>
                <h4>選択可能最大値</h4>
                <v-row align="center">
                  <v-col>
                    <v-text-field
                        :readonly="!isEdit"
                        v-model="releaseDaysMax"
                        @change="changeReleaseDaysMax()"
                        :min="Math.max(releaseDaysMin, 0)"
                        type="number"
                        hide-details
                        dense
                    />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <span>営業日後</span>
                  </v-col>
                </v-row>
                <div class="point-rate my-2">
                  <v-row align="center" class="ml-0">
                    <v-col cols="auto" class="pa-0">
                      <h4>ポイント倍率を指定する</h4>
                    </v-col>
                    <v-col cols="auto" class="pa-0">
                      <v-switch
                          class="mt-2"
                          v-model="usePointRate"
                          :readonly="!isEdit"
                          @change="changeUsePointRate()"
                          hide-details
                          dense
                      />
                    </v-col>
                  </v-row>
                  <div v-show="usePointRate">
                    <v-row>
                      <v-col class="mb-4">
                        <span class="annotation">※営業日で換算してください</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-row
                            v-for="(pointRate, index) in editPointRates"
                            :key="index"
                        >
                          <v-col class="pt-0 pr-0">
                            <v-text-field
                                v-model="pointRate.release_days"
                                :readonly="!isEdit || !usePointRate"
                                type="number"
                                @change="changePointRate()"
                                :min="releaseDaysMin"
                                :max="releaseDaysMax"
                                label="~日後"
                                hide-details
                                dense
                            />
                          </v-col>
                          <v-col class="pt-0 pr-0">
                            <v-text-field
                                v-model="pointRate.point_rate"
                                :readonly="!isEdit || !usePointRate"
                                type="number"
                                @change="changePointRate()"
                                min="0"
                                label="倍率"
                                hide-details
                                dense
                            />
                          </v-col>
                          <v-col class="pt-0 px-0">
                            <v-btn
                                :disabled="!isEdit || !usePointRate"
                                @click="removePointRate(index)"
                                color="error"
                                text
                            >
                              削除
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row v-if="isEdit && usePointRate && pointRateErrorMessage">
                          <v-col class="pt-0">
                            <span class="error-message">{{ pointRateErrorMessage }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn
                                :disabled="!isEdit || !usePointRate"
                                @click="addPointRate"
                                color="primary"
                            >
                              追加
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PopupHint from '../../components/PopupHint.vue'
import axios from '@/plugins/axios'

export default {
  components: { PopupHint },
  name: 'Holiday',

  data: () => ({
    ready: false,
    start: '',
    end: '',
    regularClosed: [],
    releaseDaysMin: 0,
    releaseDaysMax: 365,
    usePointRate: false,
    pointRates: [],
    editPointRates: [],
    pointRateErrorMessage: null,
    focus: '',
    holiday: [],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    isEdit: false
  }),
  methods: {
    isRegularClosed (weekday) {
      var result = this.regularClosed.indexOf(weekday)
      return (result !== -1)
    },
    eventClick (day) {
      // console.log(day)
      if (day.future && !this.isRegularClosed(day.weekday) && this.isEdit) {
        if (!this.isHoliday(day.date)) {
          this.addHoliday(day)
        } else {
          this.removeHoliday(day)
        }
      }
    },
    addHoliday (day) {
      this.holiday.push({
        start: day.date
      })
    },
    removeHoliday (day) {
      const index = this.holiday.findIndex((el) => el.start === day.date)
      console.info(index)
      this.holiday.splice(index, 1)
    },
    updateCalendar () {
    },
    setToday () {
      this.focus = ''
    },
    next () {
      this.calHoliday.next()
    },
    prev () {
      this.calHoliday.prev()
    },
    isHoliday (date) {
      const result = this.holiday.find(el => el.start === date)
      // console.info(result)
      return result
    },
    readDataFromAppSettings () {
      this.start = this.$store.getters.getAdminCompanyHoliday.start_office_hours
      this.end = this.$store.getters.getAdminCompanyHoliday.end_office_hours
      this.regularClosed = this.$store.getters.getAdminCompanyHoliday.weekly_holiday
      this.releaseDaysMin = this.$store.getters.getAdminCompanyHoliday.release_days_min
      this.releaseDaysMax = this.$store.getters.getAdminCompanyHoliday.release_days_max
      this.usePointRate = !!this.$store.getters.getAdminCompanyHoliday.use_point_rate
      this.$store.getters.getAdminCompanyHoliday.regular_holiday.forEach((value) => {
        if (value !== '') {
          this.holiday.push({ start: value })
        }
      })

      this.reloadPointRates()

      // Avoid sending data on the first data set
      this.$nextTick(function () {
        this.$watch('regularClosed', function (newVal, oldVal) {
          this.sendDataToAPI()
        })
        this.$watch('holiday', function (newVal, oldVal) {
          this.sendDataToAPI()
        })
      })
    },
    reloadPointRates () {
      const pointRates = []
      const editPointRates = []

      this.$store.getters.getAdminCompanyHoliday.point_rates.forEach((value) => {
        const pointRate = {
          release_days: value.release_days,
          point_rate: value.point_rate
        }
        pointRates.push(pointRate)
        editPointRates.push({ ...pointRate })
      })

      if (editPointRates.length === 0) {
        editPointRates.push({ release_days: null, point_rate: null })
      }

      this.pointRates = pointRates
      this.editPointRates = editPointRates
    },
    changeReleaseDaysMin () {
      const releaseDaysMin = this.releaseDaysMin
      if (releaseDaysMin === null || releaseDaysMin === '') {
        return null
      }

      const self = this

      const validate = () => {
        const min = Number(releaseDaysMin)

        if (isNaN(min) || !Number.isInteger(min)) {
          return '公開希望日の選択可能最小値は整数で入力してください。'
        }

        if (min < 0) {
          return '公開希望日の選択可能最小値は0以上で入力してください。'
        }

        if (min > 365) {
          return '公開希望日の選択可能最小値は365以下で入力してください。'
        }

        const releaseDaysMax = self.releaseDaysMax
        if (releaseDaysMax === null || releaseDaysMax === '') {
          return null
        }

        if (min > releaseDaysMax) {
          return '公開希望日の選択可能最小値は公開希望日の選択可能最大値以下で入力してください。'
        }

        return null
      }

      const message = validate()
      if (message !== null) {
        this.$store.commit('setFlashError', message)
        return
      }

      this.sendDataToAPI()
    },
    changeReleaseDaysMax () {
      const releaseDaysMax = this.releaseDaysMax
      if (releaseDaysMax === null || releaseDaysMax === '') {
        return null
      }

      const self = this

      const validate = () => {
        const max = Number(releaseDaysMax)

        if (isNaN(max) || !Number.isInteger(max)) {
          return '公開希望日の選択可能最大値は整数で入力してください。'
        }

        const releaseDaysMin = self.releaseDaysMin
        if (releaseDaysMin === null || releaseDaysMin === '') {
          if (max < 0) {
            return '公開希望日の選択可能最大値は0以上で入力してください。'
          }
          if (max > 365) {
            return '公開希望日の選択可能最大値は365以下で入力してください。'
          }
        } else if (max < releaseDaysMin) {
          return '公開希望日の選択可能最大値は公開希望日の選択可能最小値以上で入力してください。'
        }

        return null
      }

      const message = validate()
      if (message !== null) {
        this.$store.commit('setFlashError', message)
        return
      }

      if (!this.validatePointRate()) {
        this.$nextTick(() => {
          self.usePointRate = false
        })
      }

      this.sendDataToAPI()
    },
    validatePointRate () {
      if (!this.usePointRate) {
        return true
      }

      if (this.editPointRates.filter((row) => {
        return row.release_days === null || row.point_rate === null
      }).length === 1) {
        return false
      }

      if (this.editPointRates.filter((row) => {
        return row.release_days === null || row.point_rate === null
      }).length === 0) {
        this.editPointRates.sort((a, b) => {
          return a.release_days - b.release_days
        })
      }

      const self = this

      const validate = () => {
        const releaseDaysMin = self.releaseDaysMin
        if (releaseDaysMin === null || releaseDaysMin === '') {
          return '倍率を指定する場合は、公開希望日の選択可能最小値を設定してください。'
        }

        const releaseDaysMax = self.releaseDaysMax
        if (releaseDaysMax === null || releaseDaysMax === '') {
          return '倍率を指定する場合は、公開希望日の選択可能最大値を設定してください。'
        }

        let isMaxReleaseDays = false

        for (let i = 0; i < self.editPointRates.length; i++) {
          const pointRate = self.editPointRates[i]
          if (pointRate.release_days === null || pointRate.release_days === '' ||
              pointRate.point_rate === null || pointRate.point_rate === '') {
            return '倍率を指定する場合は、日後と倍率を入力してください。'
          }

          const releaseDays = Number(pointRate.release_days)
          if (isNaN(releaseDays) || !Number.isInteger(releaseDays)) {
            return releaseDays + ' 日後は整数で入力してください。'
          }

          if (releaseDays < self.releaseDaysMin || releaseDays > self.releaseDaysMax) {
            return '日後は公開希望日の選択可能最小値以上、最大値以下で入力してください。'
          }

          const rate = Number(pointRate.point_rate)
          if (isNaN(rate)) {
            return '倍率' + rate + 'は数値で入力してください。'
          }

          if (rate < 0) {
            return '倍率は0以上で入力してください。'
          }

          if (self.editPointRates.filter((row, index) => {
            return row.release_days === releaseDays && index !== i
          }).length > 0) {
            return releaseDays + '日後が重複しています。'
          }

          if (releaseDays === Number(self.releaseDaysMax)) {
            isMaxReleaseDays = true
          }
        }

        if (!isMaxReleaseDays) {
          return '最大値に該当する倍率を設定してください。'
        }

        return null
      }

      const message = validate()
      this.pointRateErrorMessage = message
      return !message
    },
    changeUsePointRate () {
      if (this.usePointRate) {
        if (!this.validatePointRate()) {
          return
        }
      }

      this.sendDataToAPI('ポイント倍率')
    },
    addPointRate () {
      this.editPointRates.push({ release_days: null, point_rate: null })
    },
    removePointRate (index) {
      this.editPointRates.splice(index, 1)
      this.changePointRate()
    },
    changePointRate () {
      if (!this.validatePointRate()) {
        return null
      }

      this.pointRates = this.editPointRates.map(pointRate => ({ ...pointRate }))
      this.sendDataToAPI('ポイント倍率')
    },
    sendDataToAPI (editTitle = '営業日設定') {
      const self = this
      const adminCompanyHolidayId = this.$store.getters.getAdminCompanyHoliday.id
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('days_off_monday', (self.regularClosed.includes(1) ? '1' : '0'))
      formData.append('days_off_tuesday', (self.regularClosed.includes(2) ? '1' : '0'))
      formData.append('days_off_wednesday', (self.regularClosed.includes(3) ? '1' : '0'))
      formData.append('days_off_thursday', (self.regularClosed.includes(4) ? '1' : '0'))
      formData.append('days_off_friday', (self.regularClosed.includes(5) ? '1' : '0'))
      formData.append('days_off_saturday', (self.regularClosed.includes(6) ? '1' : '0'))
      formData.append('days_off_sunday', (self.regularClosed.includes(0) ? '1' : '0'))
      formData.append('days_off_other', self.holiday.map((row) => { return row.start }).join(','))
      formData.append('start_office_hours', self.start ? self.start : '')
      formData.append('end_office_hours', self.end ? self.end : '')
      formData.append('release_days_min', self.releaseDaysMin)
      formData.append('release_days_max', self.releaseDaysMax)
      formData.append('use_point_rate', self.usePointRate ? '1' : '0')
      formData.append('point_rates', JSON.stringify(self.pointRates))
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/admin_company_holiday/${adminCompanyHolidayId}`, formData)
        .then(res => {
          console.log(res)
          self.$store.commit('setAdminCompanyHoliday', res.data)
          self.$store.commit('setFlashSuccess', editTitle + 'を編集しました。')
        })
        .catch(err => {
          console.log(err)
          self.$store.commit('setFlashError', editTitle + 'の編集に失敗しました。')
        })
    }
  },
  computed: {
    calHoliday () {
      return this.ready ? this.$refs.calHoliday : null
    }
  },
  created () {
    this.readDataFromAppSettings()
  },
  mounted () {
    this.ready = true
  }
}
</script>

<style scoped lang="scss">

  ::v-deep .v-calendar-monthly{
    .v-btn--fab {
      z-index: 2!important;
    }
    .v-event{
      position: absolute!important;
      left: 0;
      right: 0;
      z-index: 0;
    }
    .v-event,
    .regularHoliday{
      // position: initial;
      background-color: #e06060!important;
      color: white!important;
      text-align: center;
      width: auto!important;
      height: 50%!important;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: auto;
      font-size: .8em;
    }
    .v-calendar-weekly__day-label{
      padding: 5% 0;
      margin:0;
    }

  }

  #holiday.editing{
    ::v-deep .v-calendar-monthly{
      .v-calendar-weekly__day.v-future{
        cursor: pointer;
      }
    }

  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .cal .v-card__text{
      padding: 0;
    }
  }

.regularHoliday,
.passed{
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}

.regularHoliday{
  z-index: 1;
  position: relative;
}
.passed{
  color: #aaa;
  cursor: default;
  &:before{
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.today{
  color: white;
  background-color: var(--v-primary-base);
  width: 22px;
  aspect-ratio: 1/1;
  display: inline-block;
  line-height: 22px;
  border-radius: 50%;
  &:before{
    background-color: rgba(39, 38, 38, 0.05);
  }
}

.point-rate .annotation {
  font-size: .8rem;
}

.point-rate .error-message {
  color: red;
  font-size: .8rem;
}

</style>
